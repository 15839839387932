import React from "react";
import { Container, Center, VStack, Divider, Text, As, Button, Flex, useDisclosure } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { ArrowLeftIcon } from "@heroicons/react/solid";

type PageHeadlineProps = {
  title: string;
  description?: string;
  as?: As;
  isMarketplace?: boolean;
  href?: string;
  showBackBtn?: boolean;
};

export const Headline: React.FC<PageHeadlineProps> = ({ title, showBackBtn = false, description, as, href, isMarketplace }) => {
  const router = useRouter();

  const handleGoBack = () => {
    if (href) {
      router.push(href);
    } else {
      router.back();
    }
  };

  return (
    <>
      {(showBackBtn || href) && (
        <Button
          colorScheme={isMarketplace ? "lightBlue" : "darkBlue"}
          leftIcon={<ArrowLeftIcon className="w-4 h-4" />}
          borderRadius={"3xl"}
          float={"left"}
          m={4}
          cursor="pointer"
          as={"a"}
          onClick={handleGoBack}
        >
          Zurück
        </Button>
      )}

      <Container maxWidth={"container.md"} pb={4} pt={10}>
        <VStack w={"full"}>
          <Text
            px={4}
            align={"center"}
            as={as ? as : "h1"}
            color={isMarketplace ? "white" : "darkBlue.500"}
            fontSize={["24px", "28px", "32px", "36px"]}
            fontFamily="Bitter"
            fontWeight={600}
          >
            {title}
          </Text>

          <Text color={"gray.500"} textAlign={"center"}>
            {description}
          </Text>
          {description && <Divider width={"full"} />}
        </VStack>
      </Container>
    </>
  );
};
